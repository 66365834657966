<template>
  <b-card :title="$i18n.messages.Productos">
    <b-row>
      <b-col md="3" sm="4" class="my-1">
        <b-form-group
          class="mb-0"
          :label="$i18n.messages.Buscar"
          label-size="sm"
          label-for="perPageSelect"
        >
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="search" type="search" />
            <b-input-group-append>
              <b-button variant="primary" @click="listProducts()">
                {{ $i18n.messages.Buscar }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group
          class="mb-0"
          :label="$i18n.messages.Family"
          label-size="sm"
          label-for="familySelect"
        >
          <b-form-select
            id="familySelect"
            v-model="family"
            size="sm"
            :options="families"
            class="w-80"
          />
        </b-form-group>
      </b-col>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group
          class="mb-0"
          :label="$i18n.messages.Format"
          label-size="sm"
          label-for="formatSelect"
        >
          <b-form-select
            id="formatSelect"
            v-model="size"
            size="sm"
            :options="sizes"
            class="w-80"
          />
        </b-form-group>
      </b-col>
      <b-col md="2" sm="5" class="my-1">
        <b-form-group
          :label="$i18n.messages.Orden"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              v-if="user.rol_id !== 2 && user.rol_id !== 3"
              :options="sortOptions"
              style="width:60%"
            >
              <template v-slot:first>
                <option value="">
                  -- none --
                </option>
              </template>
            </b-form-select>
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              v-else
              :options="sortOptionsAgent"
              style="width:60%"
            >
              <template v-slot:first>
                <option value="">
                  -- none --
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :options="orderOptions"
              :disabled="!sortBy"
              style="width:40%"
            >
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="1" sm="2" class="my-1">
        <b-form-group
          class="mb-0"
          :label="$i18n.messages.PorPagina"
          label-size="sm"
          label-for="perPageSelect"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12 my-2">
        <b-table
          striped
          responsive
          :items="items"
          v-if="user.rol_id !== 2 && user.rol_id !== 3"
          :fields="fields"
          @sort-changed="handleSortChange"
        >
          <template #cell(actions)="data">
            <div style=" display: block;justify-content: space-around;">
              <a v-if="data.item.RutaFicha"
                 :href="data.item.RutaFicha"
                  target="_blank">
                <b-button
                    variant="primary"
                    class="btn-action"
                    style="min-width: 100px;margin: 5px"
                >
                  {{ $i18n.messages.VerFicha }}
                </b-button>
              </a>
              <router-link
                :to="{ name: 'product', params: { id: data.item.CodArt } }"
              >
                <b-button
                  variant="primary"
                  class="btn-action"
                  style="min-width: 100px; margin: 5px"
                >
                  {{ $i18n.messages.DetailProduct }}
                </b-button>
                <!--    <b-button
                size="sm"
                variant="primary"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="EyeIcon" />
              </b-button> -->
              </router-link>
              <b-button
                variant="primary"
                class="btn-action"
                @click="openModalProduct(data.item.CodArt)"
                style="min-width: 100px; margin: 5px"
              >
                {{ $i18n.messages.Anyadir2 }}
              </b-button>

              <!--  <b-button
              v-if="
                user.rol_id !== 2 &&
                  (data.item.Stock != '0' || data.item.Disponible != '0')
              "
              size="sm"
              variant="secondary"
              class="btn-icon rounded-circle"
              @click="openModalProduct(data.item.CodArt)"
            >
              <feather-icon icon="PlusIcon" />
            </b-button> -->
            </div>
          </template>
        </b-table>
        <b-table
          striped
          responsive
          :items="items"
          v-else
          :fields="fieldsAgent"
          @sort-changed="handleSortChange"
        >
          <template #cell(actions)="data">
            <div style=" display: block;justify-content: space-around;">
              <a v-if="data.item.RutaFicha"
                 :href="data.item.RutaFicha"
                  target="_blank">
                <b-button
                    variant="primary"
                    class="btn-action"
                    style="min-width: 100px; margin: 5px"
                >
                  {{ $i18n.messages.VerFicha }}
                </b-button>
              </a>
              <router-link
                :to="{ name: 'product', params: { id: data.item.CodArt } }"
              >
                <b-button
                  variant="primary"
                  class="btn-action"
                  style="min-width: 100px;margin: 5px"
                >
                  {{ $i18n.messages.DetailProduct }}
                </b-button>
                <!--    <b-button
                size="sm"
                variant="primary"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="EyeIcon" />
              </b-button> -->
              </router-link>
              <b-button
                variant="primary"
                :class="'btn-action ' + verPedido"
                @click="openModalProduct(data.item.CodArt)"
                style="min-width: 100px;margin: 5px"
              >
                {{ $i18n.messages.Anyadir2 }}
              </b-button>
              <!--  <b-button
              v-if="
                user.rol_id !== 2 &&
                  (data.item.Stock != '0' || data.item.Disponible != '0')
              "
              size="sm"
              variant="secondary"
              class="btn-icon rounded-circle"
              @click="openModalProduct(data.item.CodArt)"
            >
              <feather-icon icon="PlusIcon" />
            </b-button> -->
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    <modal-add-product
      :product="productAdd"
      v-if="productAdd && Object.keys(productAdd).length"
    >
    </modal-add-product>
  </b-card>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
} from "bootstrap-vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ModalAddProduct from "@/views/ModalAddProduct.vue";
export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ModalAddProduct,
  },
  data() {
    return this.customData();
  },
  computed: {
    ...mapGetters({
      items: "products/getListProducts",
      families: "families/getListFamilies",
      sizes: "sizes/getListSizes",
      productAdd: "products/getProductAdd",
      totalItems: "products/getTotalListProducts",
      user: "auth/getUser",
      currentCustomer: "customers/getCurrentCustomer",
    }),
  },
  created() {


    this.listProducts();
  },
  watch: {
    currentPage() {
      this.listProducts();
    },
    perPage() {
      this.listProducts();
    },
    sortBy() {
      this.listProducts();
    },
    sortDesc() {
      this.listProducts();
    },
    family() {
      this.listProducts();
    },
    size() {
      this.listProducts();
    },
  },
  methods: {
    ...mapActions({
      searchProducts: "products/searchProducts",
      searchSizes: "sizes/searchSizes",
      searchFamilies: "families/searchFamilies",
      getProductAdd: "products/getProductAdd",
    }),
    ...mapMutations({
      setShowModalAdd: "products/setShowModalAdd",
      setLoading: "app/setLoading",
      recoverCurrentCustomer: "customers/recoverCurrentCustomer",
    }),
    handleSortChange(context) {
      // this is called when b-table with ref "mytable" hears the 'sort-changed' event
      // that it has emitted

      // sorting logic goes here
      console.log(context);
    },
    customData() {
      const searchLocal = JSON.parse(localStorage.getItem("searchProd")) || ""; // value or null
      const familyLocal = JSON.parse(localStorage.getItem("familyProd")) || ""; // value or null
      const sizeLocal = JSON.parse(localStorage.getItem("sizeProd")) || ""; // value or null
      const currentPageLocal = JSON.parse(localStorage.getItem("currentPageProd")) || 1; // value or null
      const sortByLocal = JSON.parse(localStorage.getItem("sortByProd")) || ""; // value or null
      const sortDescLocal = JSON.parse(localStorage.getItem("sortDescProd")) || "+"; // value or null
      const verPedido = JSON.parse(localStorage.getItem("verPedido")) || ""; // value or null


      return {
        verPedido,
        pageOptions: [10, 15, 20],
        sortBy: sortByLocal,
        sortDesc: sortDescLocal,
        sortOptions: [
          { text: this.$i18n.messages.Stock, value: "stock" },
          { text: this.$i18n.messages.Fecha, value: "date" },
          { text: this.$i18n.messages.Articulo, value: "articulo" },
          { text: this.$i18n.messages.Familia, value: "familia" },
          { text: this.$i18n.messages.Formato, value: "formato" },
          { text: this.$i18n.messages.DisponibleM2, value: "disponible" },
        ],
        sortOptionsAgent: [{ text: this.$i18n.messages.Stock, value: "stock" },
          { text: this.$i18n.messages.Fecha, value: "date" },
          { text: this.$i18n.messages.Articulo, value: "articulo" },
          { text: this.$i18n.messages.Familia, value: "familia" },
          { text: this.$i18n.messages.Formato, value: "formato" },
          { text: this.$i18n.messages.DisponibleM2, value: "disponible" },],
        orderOptions: [
          { text: "Desc", value: "+" },
          { text: "Asc", value: "-" },
        ],
        currentPage: currentPageLocal,
        perPage: 10,
        search: searchLocal,
        sort: "",
        family: familyLocal,
        size: sizeLocal,
        fields: [
          {
            key: "Articulo",
            label: this.$i18n.messages.Articulo,
            tdClass: "textcell",
            thStyle: { width: "20%" }
          },
          {
            key: "Familia",
            label: this.$i18n.messages.Familia,
            tdClass: "textcell",
            thStyle: { width: "12%" }
          },
          {
            key: "Formato",
            label: this.$i18n.messages.Formato,
            tdClass: "numcell",
            thStyle: { width: "15%" }
          },
          {
            key: "FechaProg",
            label: this.$i18n.messages.FechaProd,
            tdClass: "numcell",
            formatter(value) {
              if (value === null) {
                return "-";
              } else {
                return (
                  value.split("/")[1] +
                  "/" +
                  value.split("/")[0] +
                  "/" +
                  value.split("/")[2]
                );
              }
            },
            thStyle: { width: "8%" }
          },
          {
            key: "Stock",
            label: this.$i18n.messages.Stock,
            formatter(value) {
              return Number(parseFloat(value)).toLocaleString("de-DE", {
                maximumFractionDigits: 4,
              });
            },
            tdClass: "numcell",
            thStyle: { width: "10%" }
          },
          {
            key: "Disponible",
            label: this.$i18n.messages.DisponibleM2,
            formatter(value) {
              return Number(parseFloat(value)).toLocaleString("de-DE", {
                maximumFractionDigits: 4,
              });
            },
            tdClass: "numcell",
          },
          {
            key: "Actions",
            label: "",
          },
        ],
        fieldsAgent: [
          {
            key: "Articulo",
            label: this.$i18n.messages.Articulo,
            tdClass: "textcell",
          },
          {
            key: "Familia",
            label: this.$i18n.messages.Familia,
            tdClass: "textcell",
          },
          {
            key: "Formato",
            label: this.$i18n.messages.Formato,
            tdClass: "numcell",
            thStyle: { width: "10%" }
          },
          {
            key: "FechaProg",
            label: this.$i18n.messages.FechaProd,
            tdClass: "numcell",
            formatter(value) {
              if (value === null) {
                return "-";
              } else {
                return (
                  value.split("/")[1] +
                  "/" +
                  value.split("/")[0] +
                  "/" +
                  value.split("/")[2]
                );
              }
            },
          },
          {
            key: "Disponible",
            label: this.$i18n.messages.DisponibleM2,
            formatter(value) {
              return Number(parseFloat(value)).toLocaleString("de-DE", {
                maximumFractionDigits: 4,
              });
            },
            tdClass: "numcell",
          },
          {
            key: "Actions",
            label: "",
          },
        ],
      };
    },

    listProducts() {
      const {
        search,
        currentPage,
        perPage,
        sortBy,
        sortDesc,
        family,
        size,
      } = this;

      localStorage.setItem("searchProd", JSON.stringify(search));
      localStorage.setItem("familyProd", JSON.stringify(family));
      localStorage.setItem("sizeProd", JSON.stringify(size));
      localStorage.setItem("currentPageProd", JSON.stringify(currentPage));
      localStorage.setItem("sortByProd", JSON.stringify(sortBy));
      localStorage.setItem("sortDescProd", JSON.stringify(sortDesc));

      const limit = perPage;
      const offset = (currentPage - 1) * perPage;
      let sort = "";
      if (sortBy) {
        sort = sortDesc + sortBy;
      }
      this.setLoading(true);
      this.searchSizes({family, search});
      this.searchFamilies({size, search});
      this.searchProducts({ search, limit, offset, sort, family, size }).then(
        () => {
          this.setLoading(false);
        }
      );
    },
    resetFilters() {
      Object.assign(this.$data, this.customData());
    },
    async openModalProduct(getProduct) {
      if (this.currentCustomer === "" || this.currentCustomer === null) {
        this.recoverCurrentCustomer();
        /*  this.modalShow = true; */
      } else {
        this.setLoading(true);
        await this.getProductAdd(getProduct);
        await this.setShowModalAdd(true);
        this.setLoading(false);
      }
    },
  },
};
</script>
